import { FormField } from '@/types';

import Select from '@components/Form/Select';

function SelectField(props: FormField) {
  const { choices, clean_name: cleanName, label, default_value: defaultValue, required } = props;

  return (
    <Select
      items={choices
        .replaceAll('\r\n', ',')
        .split(',')
        .map(choice => ({ text: choice, value: choice }))}
      placeholder={defaultValue}
      label={label}
      name={cleanName}
      grey
      required={required}
    />
  );
}
export default SelectField;
