import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';
import * as SelectPrimitive from '@radix-ui/react-select';
import clsx from 'clsx';
import { useId } from 'react';
import Label from '../Label';

type Props = {
  items: string[] | { value: string; text: string }[];
  label: string;
  placeholder: string;
  name: string;
  grey?: boolean;
  rounded?: boolean;
  required?: boolean;
};

function Select(props: Props) {
  const { items, name, label, placeholder, grey, rounded, required } = props;
  const id = useId();

  return (
    <div className={clsx('select', { 'select--grey': grey, 'select--rounded': rounded })}>
      <Label text={label} htmlFor={`${id}-${name}`} />
      <SelectPrimitive.Root name={name} required={required}>
        <SelectPrimitive.Trigger className='select__trigger' id={`${id}-${name}`}>
          <SelectPrimitive.Value placeholder={placeholder} />
          <SelectPrimitive.Icon className='select__icon'>
            <ChevronDownIcon color='white' width={48} height={48} />
          </SelectPrimitive.Icon>
        </SelectPrimitive.Trigger>
        <SelectPrimitive.Portal>
          <SelectPrimitive.Content
            className={clsx('select__content', {
              'select__content--grey': grey,
              'select__content--rounded': rounded,
            })}
            align='end'
          >
            <SelectPrimitive.ScrollUpButton className='select__scroll-button'>
              <ChevronUpIcon color='white' width={24} height={24} />
            </SelectPrimitive.ScrollUpButton>
            <SelectPrimitive.Viewport className='select__viewport'>
              {items.map(item => (
                <SelectPrimitive.Item
                  key={typeof item === 'string' ? item : item.value}
                  value={typeof item === 'string' ? item : item.value}
                  className='select__item'
                >
                  <SelectPrimitive.ItemText className='select__item-text'>
                    {typeof item === 'string' ? item : item.text}
                  </SelectPrimitive.ItemText>
                  <SelectPrimitive.ItemIndicator className='select__item-indicator'>
                    <CheckIcon width={24} height={24} color='#183e75' />
                  </SelectPrimitive.ItemIndicator>
                </SelectPrimitive.Item>
              ))}
            </SelectPrimitive.Viewport>
            <SelectPrimitive.ScrollDownButton className='select__scroll-button'>
              <ChevronDownIcon color='white' width={24} height={24} />
            </SelectPrimitive.ScrollDownButton>
          </SelectPrimitive.Content>
        </SelectPrimitive.Portal>
      </SelectPrimitive.Root>
    </div>
  );
}
export default Select;
