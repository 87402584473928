import { FormField } from '@/types';
import RadioGroup from '@components/Form/RadioGroup';
import React from 'react';

function RadioField(props: FormField) {
  const { label, clean_name: cleanName, choices, required } = props;

  return (
    <RadioGroup
      required={required}
      id={cleanName}
      label={label}
      items={choices
        .replaceAll('\r\n', ',')
        .split(',')
        .map(choice => ({ label: choice }))}
    />
  );
}

export default RadioField;
