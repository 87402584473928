'use client';

import { FormField, Streamfield, WagtailImage } from '@/types';
import BannerImage from '@components/BannerImage';
import FormFields from '@components/Form/FormFields';
import Hero from '@components/Hero';
import Honeypot from '@components/Honeypot';
import RawHtml from '@components/RawHtml';
import Streamfields from '@streamfields/Streamfields';
import { useFormState } from 'react-dom';
import SubmitButton from './SubmitButton';
import { submitForm } from './actions';

type Props = {
  title: string;
  before_form: Streamfield[];
  after_form: Streamfield[];
  thank_you_text: string;
  form_fields: FormField[];
  banner_image?: WagtailImage | null;
};

function FormPage(props: Props) {
  const {
    title = '',
    form_fields: formFields,
    before_form: beforeForm,
    after_form: afterForm,
    thank_you_text: thankYouText,
    banner_image: bannerImage,
  } = props;

  const [state, formAction] = useFormState(submitForm, { status: 'idle' });

  return (
    <>
      {bannerImage ? <BannerImage image={bannerImage} /> : null}
      <Hero title={title} />
      {beforeForm ? <Streamfields fields={beforeForm} /> : null}
      <form id='formular' className='form streamfield' action={formAction}>
        <Honeypot />
        <input
          type='hidden'
          name='form-fields'
          value={JSON.stringify(
            formFields.map(field => ({
              name: field.clean_name,
              type: field.field_type,
              required: field.required,
            })),
          )}
        />
        <input type='hidden' name='page' value={props?.form_fields?.[0]?.page_id} />
        <FormFields
          formFields={formFields}
          errors={state.status === 'error' ? state?.errors : undefined}
        />
        {state.status === 'success' ? (
          <div className='message message--success'>
            <RawHtml html={thankYouText} />
          </div>
        ) : null}
        {state.status === 'error' ? (
          <div className='message message--error'>
            <p>Leider ist etwas schief gegangen... Bitte versuchen Sie es später erneut...</p>
            <p>
              Alternativ können Sie Ihre Anfrage auch direkt an die E-Mail-Adresse{' '}
              <a href='mailto:YOUR EMAIL HERE'>YOUR EMAIL HERE</a> senden.
            </p>
          </div>
        ) : null}
        <SubmitButton status={state.status} />
      </form>
      {afterForm ? <Streamfields fields={afterForm} /> : null}
    </>
  );
}

export default FormPage;
