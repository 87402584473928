function Honeypot() {
  return (
    <input
      type='checkbox'
      name='importantfax'
      value='1'
      className='sweet'
      tabIndex={-1}
      aria-hidden='true'
      autoComplete='off'
    />
  );
}

export default Honeypot;
