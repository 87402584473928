import { FormField } from '@/types';
import Checkbox from '@components/Form/Checkbox';
import Fieldset from '@components/Form/Fieldset';
import React from 'react';

function CheckBoxesField(props: FormField) {
  const { label, choices, clean_name: cleanName } = props;

  return (
    <Fieldset legend={label}>
      {choices
        .replaceAll('\r\n', ',')
        .split(',')
        .map(choice => (
          <Checkbox id={choice} name={cleanName} label={choice} key={choice} value={choice} />
        ))}
    </Fieldset>
  );
}

export default CheckBoxesField;
