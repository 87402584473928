import { WagtailImage } from '@/types';
import Image from '@components/Image';

type Props = {
  image: WagtailImage;
};

function BannerImage(props: Props) {
  const { image } = props;

  return (
    <Image
      className='banner-image full-width'
      width={1920}
      height={500}
      image={image}
      spec='fill-1920x500'
    />
  );
}

export default BannerImage;
